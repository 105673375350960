
import SpService from "@/services/SpService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CargaFamiliarPage",
    data() {
        let ss3 = new SpService();
        return {
            msg: "CargaFamiliarPage",
            ss3: ss3,
            auth: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE_SP,
            certificado: {
                cod: null,
                cod1: null
            },
            cargafamiliar: [],
        };
    },
    methods: {
        cargaFamiliar() {
            var id = this.certificado.cod
            console.log (this.certificado.cod);
            console.log (id, "este id q se va");
            this.ss3.cargaFamiliar(id).then(
                (result) => {
                    let response = result.data;
                    this.cargafamiliar = response.data;
                    this.isLoading = false;
                    window.$('.btn-datatable-CargaFamiliar').on('click', (evt) => {
                      let data = window.$(evt.target)[0].id;
                      console.log(data)
                  });

                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        cargaFamiliarprop() {
            var id = this.certificado.cod1;
            var id2 = this.auth.CI;
            if(id === id2){
                this.ss3.cargaFamiliar(id).then(
                    (result) => {
                        let response = result.data;
                        this.cargafamiliar = response.data;
                        this.isLoading = false;
                        window.$('.btn-datatable-CargaFamiliar').on('click', (evt) => {
                          let data = window.$(evt.target)[0].id;
                          console.log(data)
                      });
    
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
            }else {
                console.log("CI incorrecto");  
                alert("CI incorrecto");
            }
            
        },
        getVerificadoStyle(verificado) {
            if (verificado) {
                return 'color: blue'; // Estilo para verdadero (azul)
            } else {
                return 'color: red'; // Estilo para falso (rojo)
            }
        },
        getVerificadoMessage(verificado) {
            if (verificado) {
                return 'Verificado'; // Mensaje para verdadero
            } else {
                return 'No verificado'; // Mensaje para falso
            }
        }
    },
    components: {
        dataTable,
        Loader
 
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
