import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");


export default {
    name: "PersonaActivoDeuda",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaActivoDeuda",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexCertificadoFiltroDeuda(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Nombre y Apellidoss' },
                { data: 'CI', name: 'p.CI', title: 'C.I.' },
                { data: 'Reparticion', name: 'tc.Reparticion', title: 'Reparticion Adeudada' },
                { data: 'Cargo', name: 'c.Cargo', title: 'Cargo' },
                { data: 'FechaDeuda', name: 'c.FechaDeuda', title: 'Fecha Deuda' },
                
                { data: 'ActivoDeuda', name: 'c.ActivoDeuda', title: 'Activo Deuda' },
                {
                    data: 'EstadoEntrega',
                    name: 'c.EstadoEntrega',
                    title: 'Estado Deuda',
                    render: function(data) {
                        // Aquí defines la lógica para convertir el estado de deuda de números a texto
                        switch (data) {
                            case 1:
                                return 'Deuda';
                            case 2:
                                return 'Solucionado';
                            case 3:
                                return 'En Proceso de Solución';
                            default:
                                return 'Desconocido';
                        }
                    }
                },
                { data: 'FechaSolucion', name: 'c.FechaSolucion', title: 'Fecha Solucion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                },
            ],
            unidadAcademicas: [],
            tipocertificados: [],
            estadocertificados: [],
            reparticion: [],
            certificados: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {  FechaDeuda: ''},
            password: {},
            certificado: {
                TipoCertificado: null,
                Archivo: null,
                FotoVoucher: null,
                 FechaDeuda: '',
            },
            cantidadtipocert: {},
            personas: [],
            reparticiones: [],
            searchPersona: "",
            searchReparticion: "",
            selectedPersona: null,
            personalogueada:{},
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            arregloElementos: [],
            cod1: 0,
            cod2: 0,
            cod3: 0,
            cod4: 0,
            cod5: 0,
            cod6: 0,
            reportes: {},
            tipocertificado: {},
            detalledocs: ['Descripcion', 'Costo'], // Lista de campos a mostrar
            filePreview: {
                Archivo: null,
                FotoVoucher: null,
            },   

            minDate: '',
            maxDate: ''

            
        };
    },
 
    
    computed: {
        filteredTipocertificado() {
            let result = [];
            for (let key in this.tipocertificado) {
                if (this.detalledocs.includes(key)) {
                    let value = this.tipocertificado[key];
                    // Si el campo es Descripcion, dividirlo por comas
                    if (key === 'Descripcion') {
                        value = value.split(',').map(item => item.trim());
                    }
                    result.push({ key: key, value: value });
                }
            }
            return result;
        }
    },

    created() {
        this.setMinMaxDates();
    },

    methods: {
     setMinMaxDates() {
            const today = new Date();
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(today.getMonth() - 3);

            const oneDayAfter = new Date();
            oneDayAfter.setDate(today.getDate() + 1);

            this.minDate = threeMonthsAgo.toISOString().split('T')[0];
            this.maxDate = oneDayAfter.toISOString().split('T')[0];
        },

        formatEstadoDeuda(data) {
            // Aquí defines la lógica para convertir el estado de deuda de números a texto
            switch (data) {
                case 1:
                    return 'Deuda';
                case 2:
                    return 'Solucionado';
                case 3:
                    return 'En Proceso de Solución';
                default:
                    return 'Desconocido';
            }
        },

        isImage(url) {
            return /\.(jpg|jpeg|png|gif|bmp|svg)$/.test(url);
        },
        isPDF(url) {
            return /\.pdf$/.test(url);
        },
        aplicarFiltro(){
            let PersonaRol = this.auth.id
            this.ajax.data = function(d){
                d.PersonaRol = PersonaRol
            }
            this.$refs['datatable-certificado'].reloadAjax(this.ajax);
        },

        limpiar(){
            document.getElementById("PersonaRol").value = "";
            this.$refs['datatable-certificado'].reloadAjax();
        },

        getTipoCertificado() {
            this.ss.listTipoCertificado().then(
                (result) => {
                    let response = result.data;
                    this.tipocertificados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEstadoCertificado() {
            this.ss.listEstadoCertificado().then(
                (result) => {
                    let response = result.data;
                    this.estadocertificados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticion = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

       

        loadFile(inputType) {
            this.isLoadingFile = true;
            let input = event.target;
            if (input.files && input.files[0]) {
                let data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            if (inputType === 'DocumentoRespaldoDeuda') {
                                this.certificado.DocumentoRespaldoDeuda = result.data.data;
                            } else if (inputType === 'FotoVoucher') {
                                this.certificado.FotoVoucher = result.data.data;
                            }
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                                }
                            );
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        );
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFiles(inputType) {
            this.isLoadingFile = true;
            let input = event.target;
            if (input.files && input.files[0]) {
                let data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            if (inputType === 'DocumentoSolucionDeuda') {
                                this.certificado.DocumentoSolucionDeuda = result.data.data;
                            } else if (inputType === 'FotoVoucher') {
                                this.certificado.FotoVoucher = result.data.data;
                            }
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                                }
                            );
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        );
                        this.isLoadingFile = false;
                    });
            }
        },


        showTipoCertificado(id) {
            this.isLoading = true;
            this.ss.showTipoCertificado(id)
                .then(result => {
                    let response = result.data;
                    this.tipocertificado = response.data; // Actualiza los datos del tipo de certificado
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        newCertificado() {
            this.certificado = {};
            this.$refs['frm-personaDeuda'].show();
        },
        
        showPersonaDeuda(id) {

            this.isLoading = true;
            this.ss.showPersonaDeuda(id).then(
                (result) => {
                    let response = result.data;
                    this.certificado = response.data;
                    this.$refs['view-personaDeuda'].show();
                    this.isLoading = false;
                }

            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        editCertificado() {
            // this.$refs['frm-personaDeuda'].show();
            // this.$refs['view-personaDeuda'].hide();
            this.$refs['view-personaDeuda'].hide();
            this.$refs['frm-personaDeuda'].show();
          
        },

        cancelCertificado() {
            // if (this.certificado.id) {
            //     this.$refs['view-personaDeuda'].show();
            // }
            // this.$refs['frm-personaDeuda'].hide();
            this.$refs['view-personaDeuda'].hide();

        },

        saveDeuda() {
            this.certificado.TipoSolicitud = 1;
            this.certificado.CodigoCertificado = this.cantidadtipocert + 1;
            this.certificado.Codigo = this.cod6;
            this.certificado.Concatenado = this.cod6;
            this.ss.storeDeuda(this.certificado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-personaDeuda'].hide();
                    this.$refs['datatable-personaDeuda'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        saveCertificadoWeb() {
            this.certificado.Persona = this.personalogueada.id;
            this.certificado.TipoSolicitud = 2;
            this.certificado.EstadoCertificado = 5;
            this.ss.storeCertificado(this.certificado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-certificadosolicitud'].hide();
                    this.$refs['datatable-certificado'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        draw() {

            window.$('.btn-datatable-personaDeuda').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersonaDeuda(data);
            });

        },


        showcantidadtipocert() {
                this.isLoading=true;
                console.log (this.certificado.TipoCertificado);
                this.ss.showcantTipoCertificado(this.certificado.TipoCertificado).then(
                    (result) => {
                        let response = result.data;
                        this.cantidadtipocert = response.data;
                        console.log(this.cantidadtipocert)
                        this.isLoading=false;
                        this.codigoControl();
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
        },

        codigoControl() {
                console.log (this.certificado.CI);
                console.log (this.certificado.NombreCompleto);
                console.log (this.certificado.Nombres);
                console.log (this.certificado.ApellidoPaterno);
                console.log (this.certificado.ApellidoMaterno);
                this.cod1 = this.certificado.Nombres.length
                this.cod2 = this.certificado.ApellidoPaterno.length
                this.cod3 = this.certificado.ApellidoMaterno.length
                this.cod4 = this.certificado.NombreCompleto.length
                this.cod5 = this.certificado.CI.substr(-3);
                console.log (this.cod1);
                console.log (this.cod2);
                console.log (this.cod3);
                console.log (this.cod4);
                console.log (this.cod5);
                this.cod6 = this.cod1+""+this.cod2+""+this.cod3+""+this.cod5+""+(this.cantidadtipocert + 1)+"23"+""+((this.cantidadtipocert + 1)+this.cod1);
                console.log ('este es cod final',this.cod6);
        },

        newSolicitud() {
            this.certificado = {};
            this.$refs['frm-certificadosolicitud'].show();
            this.showpersonalogueada()
        },  

        showpersonalogueada() {
            this.isLoading=true;
            console.log (this.auth.id);
            this.ss.showPersona(this.auth.id).then(
                (result) => {
                    let response = result.data;
                    this.personalogueada = response.data;
                    console.log(this.personalogueada)
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, 
        printCerAprobado() {
            console.log(this.certificado.id);
            this.ss.printCerAprobado(this.certificado.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
   
   
        
        

    },
    watch: {
        searchPersona(q) {
            this.ss.select2Persona(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.personas = res.data.items;
                } else {
                    this.personas = [];
                }
            })
        },

        searchReparticion(q) {
            this.ss.select2Reparticion(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.reparticiones = res.data.items;
                } else {
                    this.reparticiones = [];
                }
            })
        },
        // selectedPersona(persona){
        //     console.log(persona)
        //     this.certificado.Persona = persona.id;
        //     this.showPersona(persona)
        // },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getTipoCertificado();
            this.getEstadoCertificado();
            this.getReparticion();
            this.aplicarFiltro();
        }
    }
    
};

