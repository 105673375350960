import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
var axios = require('axios');
window.$ = window.jQuery = require("jquery");

export default {
    name: "CertificadoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CertificadoPage",
            ss: ss,
            auth: {},
            ajax: {
                "url": ss.indexCertificadodptov(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'CodigoCertificado', name: 'c.CodigoCertificado', title: 'Cod. Certificado', width: 50, cellClass: 'center-align', },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'CI', name: 'p.CI', title: 'C.I.' },
                { data: 'FechaElaboracion', name: 'c.FechaElaboracion', title: 'Fecha Elab/Solicitud' },
                { data: 'Concatenado', name: 'c.Concatenado', title: 'Código' },
                { data: 'TipoSolicitud', name: 'c.TipoSolicitud', title: 'TipoSolicitud', render:function(data, type, row){
                    if (row.TipoSolicitud==2){return '<i class="fa fa-envelope text-indigo  "> WEB</i></span>';}
                    else{ return '<i class="fa fa-laptop text-blue "> INTERNO </i></span>';}
                    }, searchable: true 
                },
                {
                    data: 'EstadoCertificado',
                    name: 'c.EstadoCertificado',
                    title: 'Estado',
                    render: function (data, type, row) {
                        switch (row.EstadoCertificado) {
                            case 1:
                                return '<i class="fa fa-times text-danger font-italic font-weight-bold"> Borrador </i>';
                            case 2:
                                return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i>';
                            case 3:
                                return '<i class="fa fa-exclamation-triangle text-warning font-italic font-weight-bold"> Observado </i>';
                            case 4:
                                return '<i class="fa fa-times text-warning font-italic font-weight-bold"> Anulado </i>';
                            case 5:
                                return '<i class="fa fa-paper-plane text-info font-italic font-weight-bold"> Solicitud </i>';
                            case 6:
                                return '<i class="fa fa-dollar-sign text-primary font-italic font-weight-bold"> Pagado </i>';
                            case 7:
                                return '<i class="fa fa-check-double text-secondary font-italic font-weight-bold"> Revisado </i>';
                            default:
                                return '<i class="fa fa-question text-muted font-italic font-weight-bold"> Desconocido </i>';
                        }
                    },
                },
                { data: 'Aprobado', name: 'c.Aprobado', title: 'Aprobado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                    else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                    } 
                },
                { data: 'TipoCertificado', name: 'tc.TipoCertificado', title: 'Tipo Certificado' },
                //{ data: 'NumeroDocumentoEntrega', name: 'c.NumeroDocumentoEntrega', title: 'Num Vaucher' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                },
            ],
            unidadAcademicas: [],
            tipocertificados: [],
            estadocertificados: [],
            contarduplicados: [],
            certificados: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            certificado: {},
            cantidadtipocert: {},
            personas: [],
            searchPersona: "",
            selectedPersona: null,
            personalogueada:{},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            arregloElementos: [],
            cod1: 0,
            cod2: 0,
            cod3: 0,
            cod4: 0,
            cod5: 0,
            cod6: 0,
            reportes: {},
            depositoValido: false,
            estadocertificado: {},
        };
    },
    created() {
        this.getContarDuplicados(); // Llama a la función al inicio
        setInterval(this.getContarDuplicados, 5000000); // Llama a la función cada 5 segundos

        this.checkDepositStatus(); // Llama a la función al inicio
        setInterval(this.checkDepositStatus, 5000000); 
      },
      computed: {
        tipoCertificado() {
            return this.certificado.tipocertificado ? this.certificado.tipocertificado.TipoCertificado : "Sin Tipo Asignado";
        },
        estadoCertificado() {
            switch (this.certificado.EstadoCertificado) {
                case 1:
                    return "Borrador";
                case 2:
                    return "Aprobado";
                case 3:
                    return "Observado";
                case 4:
                    return "Anulado";
                case 5:
                    return "Solicitud";
                case 6:
                    return "Pagado";
                case 7:
                    return "Revisado";
                default:
                    return "Estado desconocido";
            }
        }
    },
    methods: {
        isImage(url) {
            return /\.(jpg|jpeg|png|gif|bmp|svg)$/.test(url);
        },
        isPDF(url) {
            return /\.pdf$/.test(url);
        },
        getTipoCertificado() {
            this.ss.listTipoCertificado().then(
                (result) => {
                    let response = result.data;
                    this.tipocertificados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEstadoCertificado() {
            this.ss.listEstadoCertificado().then(
                (result) => {
                    let response = result.data;
                    this.estadocertificados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getContarDuplicados() {
            this.ss.ContarDuplicados().then(
                (result) => {
                    let response = result;
                    this.contarduplicados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.certificado.Archivo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        newCertificado() {
            this.certificado = {};
            this.$refs['frm-certificado'].show();
        },
        
        showCertificado(id) {
            this.isLoading = true;
            this.ss.showCertificado(id).then(
                (result) => {
                    let response = result.data;
                    this.certificado = response.data;
                    this.$refs['view-certificado'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        // checkDepositStatus() {
        //     const nroDeposito = this.certificado.NumeroDocumentoEntrega;
        //     if (nroDeposito) {
        //         this.sssa.showDeposit({ NroDeposito: nroDeposito })
        //             .then(response => {
        //                 this.depositoValido = response.data.success;
        //             })
        //             .catch(error => {
        //                 console.log(error);
        //                 this.isLoading = false;
        //             });
        //     }
        // },

        checkDepositStatus() {
            const nroDeposito = this.certificado.NumeroDocumentoEntrega;
            if (nroDeposito) {
                axios.get(`http://localhost:8002/api/IngresoscDetalleCerti/showDeposit`, {
                    params: {
                        NroDeposito: nroDeposito
                    }
                })
                .then(response => {
                    this.depositoValido = response.data.success;
                })
                .catch(error => {
                    console.log(error);
                    this.depositoValido = false;
                });
            }
        },
        

        editCertificado() {
            this.$refs['frm-certificado'].show();
            this.$refs['view-certificado'].hide();
        },

        cancelCertificado() {
            if (this.certificado.id) {
                this.$refs['view-certificado'].show();
            }
            this.$refs['frm-certificado'].hide();
        },

        saveCertificado() {
            this.certificado.TipoSolicitud = 1;
            this.certificado.CodigoCertificado = this.cantidadtipocert + 1;
            this.certificado.Codigo = this.cod6;
            this.certificado.Concatenado = this.cod6;
            this.ss.storeCertificado(this.certificado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-certificado'].hide();
                    this.$refs['datatable-certificado'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveEstadoCertificado() {
            this.estadocertificado.id = this.certificado.id;
            this.estadocertificado.EstadoCertificado = this.certificado.EstadoCertificado;
            this.ss.storeEstadoCertificadoOrigi(this.estadocertificado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['view-certificado'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        saveCertificadoWeb() {
            this.certificado.Persona = this.personalogueada.id;
            this.certificado.TipoSolicitud = 2;
            this.ss.storeCertificado(this.certificado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-certificado'].hide();
                    this.$refs['datatable-certificado'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        deleteCertificado() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyCertificado(this.certificado)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-certificado'].hide();
                                this.$refs['datatable-certificado'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        draw() {
            window.$('.btn-datatable-Certificado').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCertificado(data);
            });
        },

        showcantidadtipocert() {
                this.isLoading=true;
                console.log (this.certificado.TipoCertificado);
                this.ss.showcantTipoCertificado(this.certificado.TipoCertificado).then(
                    (result) => {
                        let response = result.data;
                        this.cantidadtipocert = response.data;
                        console.log(this.cantidadtipocert)
                        this.isLoading=false;
                        this.codigoControl();
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
        },

        codigoControl() {
                console.log (this.certificado.CI);
                console.log (this.certificado.NombreCompleto);
                console.log (this.certificado.Nombres);
                console.log (this.certificado.ApellidoPaterno);
                console.log (this.certificado.ApellidoMaterno);
                this.cod1 = this.certificado.Nombres.length
                this.cod2 = this.certificado.ApellidoPaterno.length
                this.cod3 = this.certificado.ApellidoMaterno.length
                this.cod4 = this.certificado.NombreCompleto.length
                this.cod5 = this.certificado.CI.substr(1, 3);
                console.log (this.cod1);
                console.log (this.cod2);
                console.log (this.cod3);
                console.log (this.cod4);
                console.log (this.cod5);
                this.cod6 = this.cod1+""+this.cod2+""+this.cod3+""+this.cod5+""+(this.cantidadtipocert + 1)+"24"+""+((this.cantidadtipocert + 1)+this.cod1);
                console.log ('este es cod final',this.cod6);
        },

        newSolicitud() {
            this.certificado = {};
            this.$refs['frm-certificadosolicitud'].show();
            this.showpersonalogueada()
        },  

        showpersonalogueada() {
            this.isLoading=true;
            console.log (this.auth.id);
            this.ss.showPersona(this.auth.id).then(
                (result) => {
                    let response = result.data;
                    this.personalogueada = response.data;
                    console.log(this.personalogueada)
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, 
        printCerAprobadodptov() {
            console.log(this.certificado.id);
            this.ss.printCerAprobadodptov(this.certificado.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        printCerAprobadodptovdos() {
            console.log(this.certificado.id);
            this.ss.printCerAprobadodptovdos(this.certificado.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        printCerObservadodptov() {
            console.log(this.certificado.id);
            this.ss.printCerObservadodptov(this.certificado.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        

        abrirModalReportes() {
            this.reporte = {};
            this.$refs['frm-reportes'].show();
        },
        setReset() {
            this.reportes = {};
        },
        cancelArchivoC() {
            if (this.reportes.id) {
                this.$refs['frm-reportes'].show();
            }
            this.$refs['frm-reportes'].hide();
        },
        imprimirListaCertificados() {
            this.ss.imprimirListaCertificados(this.reportes)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        }, 

    },
    watch: {
        searchPersona(q) {
            this.ss.select2Persona(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.personas = res.data.items;
                } else {
                    this.personas = [];
                }
            })
        },
        // selectedPersona(persona){
        //     console.log(persona)
        //     this.certificado.Persona = persona.id;
        //     this.showPersona(persona)
        // },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getTipoCertificado();
            this.getEstadoCertificado();
            this.getContarDuplicados();
        }
        this.checkDepositStatus();
        setInterval(this.checkDepositStatus, 20000); // Verificar cada 20 segundos
    }
    
};
