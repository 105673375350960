var axios = require('axios');

export default class SafiService {

    constructor() {
        
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE_SAFI;
    }

   //Consultar Fondo Rotativo
   showConsultarFr(id) {
    return axios.get('api/FondoRot/showconsultafr?id='+ id);
    }

   //Consultar Cargo Cuenta
   showConsultarCc(id) {
    return axios.get('api/FondoRot/showconsultacc?id='+ id);
    }

   //Consultar Deposito
   showDeposit(id) {
    return axios.get('api/IngresoscDetalleCerti/showDeposit?id='+ id);
    }

}
