import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import LineChart from '@/components/vue-chartjs/LineChart'
import BarChart from '@/components/vue-chartjs/BarChart'
import RadarChart from '@/components/vue-chartjs/RadarChart'
import PolarAreaChart from '@/components/vue-chartjs/PolarAreaChart'
import PieChart from '@/components/vue-chartjs/PieChart'
import DoughnutChart from '@/components/vue-chartjs/DoughnutChart'
window.$ = window.jQuery = require("jquery");

export default {
    name: "DashboCerti",
    data () {
        let ss = new MainService();
		var randomScalingFactor = function() { 
			return Math.round(Math.random()*100)
		};
		return {
            msg: "ArmaPage",
            ss: ss,
            auth: {},

			lineChart: {
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					datasets: [{
							label: 'Dataset 1',
							borderColor: '#348fe2',
							pointBackgroundColor: '#348fe2',
							pointRadius: 2,
							borderWidth: 2,
							backgroundColor: 'rgba(52, 143, 226, 0.3)',
							data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()]
					}, {
							label: 'Dataset 2',
							borderColor: '#2d353c',
							pointBackgroundColor: '#2d353c',
							pointRadius: 2,
							borderWidth: 2,
							backgroundColor: 'rgba(45, 53, 60, 0.3)',
							data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()]
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false,
					hover: {
						mode: 'nearest',
						intersect: true
					},
					tooltips: {
						mode: 'index',
						intersect: false,
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero:true,
								max: 100
							}
						}]
					}
				}
			},
			barChart: {
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					datasets: [{
						label: 'Dataset 1',
						borderWidth: 2,
						borderColor: '#727cb6',
						backgroundColor: 'rgba(114, 124, 182, 0.3)',
						data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()]
					}, {
						label: 'Dataset 2',
						borderWidth: 2,
						borderColor: '#2d353c',
						backgroundColor: 'rgba(45, 53, 60, 0.3)',
						data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()]
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
			radarChart: {
				data: {
					labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
					datasets: [{
						label: 'Dataset 1',
						borderWidth: 2,
						borderColor: '#ff5b57',
						pointBackgroundColor: '#ff5b57',
						pointRadius: 2,
						backgroundColor: 'rgba(255, 91, 87, 0.2)',
						data: [65,59,90,81,56,55,40]
					}, {
						label: 'Dataset 2',
						borderWidth: 2,
						borderColor: '#2d353c',
						pointBackgroundColor: '#2d353c',
						pointRadius: 2,
						backgroundColor: 'rgba(45, 53, 60, 0.2)',
						data: [28,48,40,19,96,27,100]
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
			polarAreaChart: {
				data: {
					labels: ['Dataset 1', 'Dataset 2', 'Dataset 3', 'Dataset 4', 'Dataset 5'],
					datasets: [{
						data: [300, 160, 100, 200, 120],
						backgroundColor: ['rgba(114, 124, 182, 0.7)', 'rgba(52, 143, 226, 0.7)', 'rgba(0, 172, 172, 0.7)', 'rgba(182, 194, 201, 0.7)', 'rgba(45, 53, 60, 0.7)'],
						borderColor: ['#727cb6', '#348fe2', '#00ACAC', '#b6c2c9', '#2d353c'],
						borderWidth: 2,
						label: 'My dataset'
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
			pieChart: {
				data: {
					labels: ['Dataset 1', 'Dataset 2', 'Dataset 3', 'Dataset 4', 'Dataset 5'],
					datasets: [{
						data: [300, 50, 100, 40, 120],
						backgroundColor: ['rgba(255, 91, 87, 0.7)', 'rgba(245, 156, 26, 0.7)', 'rgba(240, 243, 244, 0.7)', 'rgba(182, 194, 201, 0.7)', 'rgba(45, 53, 60, 0.7)'],
						borderColor: ['#ff5b57', '#f59c1a', '#b4b6b7', '#b6c2c9', '#2d353c'],
						borderWidth: 2,
						label: 'My dataset'
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
			doughnutChart: {
				data: {
					labels: ['Dataset 1', 'Dataset 2', 'Dataset 3', 'Dataset 4', 'Dataset 5'],
					datasets: [{
						data: [300, 50, 100, 40, 120],
						backgroundColor: ['rgba(114, 124, 182, 0.7)', 'rgba(52, 143, 226, 0.7)', 'rgba(0, 172, 172, 0.7)', 'rgba(182, 194, 201, 0.7)', 'rgba(45, 53, 60, 0.7)'],
						borderColor: ['#727cb6', '#348fe2', '#00ACAC', '#b6c2c9', '#2d353c'],
						borderWidth: 2,
						label: 'My dataset'
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			}
		}	
	},
    methods: {

       
    },
    components: {
        dataTable,
        Loader,
        LineChart,
        BarChart,
        RadarChart,
        PolarAreaChart,
        PieChart,
        DoughnutChart
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
