
import SafiService from "@/services/SafiService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "FondoRotativoPage",
    data() {
        let ss2 = new SafiService();
        return {
            msg: "FondoRotativoPage",
            ss2: ss2,
            auth: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE_SAFI,
            certificado: {
                cod: null,
                cod1: null
            },
            consulta: [],
            noDataMessage: '',
        };
    },
    methods: {
        showConsultarFr() {
            var id = this.certificado.cod;
            console.log(this.certificado.cod);
            console.log(id, "este id q se va");
            this.isLoading = true; // Añadido para mostrar un indicador de carga
            this.ss2.showConsultarFr(id).then(
              (result) => {
                let response = result.data;
                this.consulta = response.data;
                this.isLoading = false;
                if (this.consulta.length === 0) {
                  this.noDataMessage = "No se encontraron registros.";
                } else {
                  this.noDataMessage = ''; // Limpia el mensaje si hay datos
                }
                window.$('.btn-datatable-FondoRotativo').on('click', (evt) => {
                  let data = window.$(evt.target)[0].id;
                  console.log(data);
                });
              }
            ).catch(error => {
              console.log(error);
              this.isLoading = false;
              this.noDataMessage = "Error al consultar los datos.";
            });
          },
          
          showConsultarFrprop() {
            var id = this.certificado.cod1;
            var id2 = this.auth.CI;
            if (id === id2) {
              this.isLoading = true; // Añadido para mostrar un indicador de carga
              this.ss2.showConsultarFr(id).then(
                (result) => {
                  let response = result.data;
                  this.consulta = response.data;
                  this.isLoading = false;
                  if (this.consulta.length === 0) {
                    this.noDataMessage = "No se encontraron registros.";
                  } else {
                    this.noDataMessage = ''; // Limpia el mensaje si hay datos
                  }
                  window.$('.btn-datatable-FondoRotativo').on('click', (evt) => {
                    let data = window.$(evt.target)[0].id;
                    console.log(data);
                  });
                }
              ).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.noDataMessage = "Error al consultar los datos.";
              });
            } else {
              console.log("CI incorrecto");
              alert("CI incorrecto");
            }
          },
        getDescargoEstadoStyle(descargoEstado) {
            if (descargoEstado === 'Aprobado Usuario supervisor y  Cierre') {
              return { color: 'blue' };
            } else if (descargoEstado === 'Aprobado por Revisor') {
              return { color: 'green' };
            } else if (descargoEstado === 'Sin Descargo') {
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          },
    },
    components: {
        dataTable,
        Loader
 
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
