<script>
import Persona from '../pages/Persona/PersonaPage.vue'
import Departamento from '../pages/Departamento/DepartamentoPage.vue'
import Fuerza from '../pages/Fuerza/FuerzaPage.vue'
import Especialidad from '../pages/Especialidad/EspecialidadPage.vue'
import Cargo from '../pages/Cargo/CargoPage.vue' 
import Escalafon from '../pages/Escalafon/EscalafonPage.vue'
import Grado from '../pages/Grado/GradoPage.vue'
import Reparticion from '../pages/Reparticion/ReparticionPage.vue'
import Profile from '../pages/Profile/ProfilePage.vue'
import Login from '../pages/Login/LoginPage.vue'
import Sexo from '../pages/Sexo/SexoPage.vue'
import GrupoSanguineo from '../pages/GrupoSanguineo/GrupoSanguineoPage.vue'
import EstadoCivil from '../pages/EstadoCivil/EstadoCivilPage.vue'
import Arma from '../pages/Arma/ArmaPage.vue'
import Validador from '../pages/Validador/Validador.vue'
import Validadorlibre from '../pages/Validadorlog/Validadorlog.vue'
import Certificado from '../pages/Certificado/CertificadoPage.vue'
import CertificadoFiltro from '../pages/CertificadoFiltro/CertificadoFiltroPage.vue'
import EstadoCertificado from '../pages/EstadoCertificado/EstadoCertificadoPage.vue'
import TipoCertificado from '../pages/TipoCertificado/TipoCertificadoPage.vue'
import FondoRotativo from '../pages/FondoRotativo/FondoRotativo.vue'
import CuentasCobrar from '../pages/CuentasCobrar/CuentasCobrar.vue'
import Aprobacion from '../pages/Aprobacion/AprobacionPage.vue'
import Rol from '../pages/Rol/RolPage.vue'
import CargoCuenta from '../pages/CargoCuenta/CargoCuenta.vue'
import CargaFamiliar from '../pages/CargaFamiliar/CargaFamiliar.vue'
import DashboCerti from '../pages/DashboCerti/DashboCerti.vue'
import DashboSafi from '../pages/DashboSafi/DashboSafi.vue'
import DashboSp from '../pages/DashboSp/DashboSp.vue'
import DashboContra from '../pages/DashboContra/DashboContra.vue'
import DashboCertiPruebas from '../pages/DashboCertiPruebas/DashboCertiPruebas.vue'
import CertificadoDptov from '../pages/CertificadoDptov/CertificadoDptovPage.vue'
import PersonaActivoDeuda from '../pages/PersonaActivoDeuda/PersonaActivoDeuda.vue' 
import PersonaDeuda from '../pages/PersonaDeuda/PersonaDeuda.vue' 
const routes = [
  { path: '/Persona', component: Persona },
  { path: '/Departamento', component: Departamento },
  { path: '/Fuerza', component: Fuerza },
  { path: '/Especialidad', component: Especialidad },
  { path: '/Cargo', component: Cargo },
  { path: '/Escalafon', component: Escalafon },
  { path: '/Grado', component: Grado },
  { path: '/Reparticion', component: Reparticion },
  { path: '/Profile', component: Profile },
  { path: '/', component: Login },
  { path: '/Sexo', component: Sexo },
  { path: '/GrupoSanguineo', component: GrupoSanguineo },
  { path: '/EstadoCivil', component: EstadoCivil },
  { path: '/EstadoCertificado', component: EstadoCertificado },
  { path: '/Arma', component: Arma },
  { path: '/Validador',  middleware: 'guest',component: Validador },
  { path: '/Validadorfree', component: Validadorlibre },
  { path: '/Certificado', component: Certificado },
  { path: '/CertificadoFiltro', component: CertificadoFiltro },
  { path: '/TipoCertificado', component: TipoCertificado },
  { path: '/CargoCuenta', component: CargoCuenta },
  { path: '/FondoRotativo', component: FondoRotativo },
  { path: '/CuentasCobrar', component: CuentasCobrar },
  { path: '/Aprobacion', component: Aprobacion },
  { path: '/Rol', component: Rol },
  { path: '/CargaFamiliar', component: CargaFamiliar },
  { path: '/DashboCerti', component: DashboCerti },
  { path: '/DashboSafi', component: DashboSafi },
  { path: '/DashboSp', component: DashboSp },
  { path: '/DashboContra', component: DashboContra },
  { path: '/DashboCertiPruebas', component: DashboCertiPruebas },
  { path: '/CertificadoDptov', component: CertificadoDptov },
  { path: '/PersonaActivoDeuda', component: PersonaActivoDeuda},
  { path: '/PersonaDeuda', component: PersonaDeuda}
]

export default routes;
</script>
