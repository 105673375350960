import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import BarChart from '@/components/vue-chartjs/BarChart'
window.$ = window.jQuery = require("jquery");

export default {
    name: "DashboCertiPruebas",
    data () {
        let ss = new MainService();
		return {
            msg: "DashboCertiPruebas",
            ss: ss,
            auth: {},
            certis: [],

			barChartt: {
				data: [{
					key: 'Cumulative Return',
					values: [
						{ 'label' : '', 'value' : '', 'color' : '#ff5b57' }
					]
				}],
				staggerLabels: false
			},
		}
	},

    methods: { 
	getCertificadostres() {
		this.ss.estadisticaCertificadodos().then(
			(result) => {
			let response = result.data;
			//this.barChartt.data[0].values = response.map((item, index) => ({
			this.barChartt.data[0].values = response.map((item) => ({
				color: item.color,
				//label: String.fromCharCode(65 + index),
				label: item.TipoCertificado,
				series: 0,
				value: item.count
			}));
			}
		).catch(error => {
			console.log(error);
		});
		}
       
    },
    components: {
        dataTable,
        Loader,
        BarChart,
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
			this.getCertificadostres();
		} 
    }
};
