<script>
const sidebarMenu = [

  { path: '/Profile', icon: 'fa fa-user', title: 'Mi Perfil', showForRoles: [1, 4, 5] },

  {
    path: '/Rol', icon: 'fa fa-medkit', title: 'Paramétricas', showForRoles: [1], hasChildren: true,
    children: [
      { path: '/Rol', title: 'Rol', showForRoles: [1] },
      { path: '/Departamento', title: 'Departamento', showForRoles: [1] },
      { path: '/Fuerza', title: 'Fuerza', showForRoles: [1] },
      { path: '/Especialidad', title: 'Especialidad', showForRoles: [1] },
      { path: '/Sexo', title: 'Sexo', showForRoles: [1] },
      { path: '/GrupoSanguineo', title: 'Grupo Sanguineo', showForRoles: [1] },
      { path: '/EstadoCivil', title: 'Estado Civil', showForRoles: [1] },
      { path: '/Escalafon', title: 'Escalafon', showForRoles: [1] },
      { path: '/Cargo', title: 'Cargo', showForRoles: [1] },
      { path: '/Grado', title: 'Grado', showForRoles: [1] },
      { path: '/Reparticion', title: 'Reparticion', showForRoles: [1] },
      { path: '/Arma', title: 'Arma', showForRoles: [1] },
    ]
  },
  {
    path: '/Rol', icon: 'fa fa-medkit', title: 'Paramétricas Otras U.U.', showForRoles: [1, 5], hasChildren: true,
    children: [
      { path: '/Departamento', title: 'Departamento', showForRoles: [1] },
      { path: '/TipoCertificado', title: 'Tipo Certificado', showForRoles: [1, 5] },
      { path: '/EstadoCertificado', title: 'Estado Certificado', showForRoles: [1] },
      { path: '/AprobacionFirmas', icon: 'fa fa-file', title: 'Gestor de Firmas', showForRoles: [1] }
    ]
  },
  { path: '/Persona', icon: 'fa fa-file', title: 'Personal Ejto.', showForRoles: [1] },
  { path: '/Certificado', icon: 'fa fa-file', title: 'Generar Certificados Admin', showForRoles: [1] },
  { path: '/CertificadoFiltro', icon: 'fa fa-file', title: 'Solicitud de Certificados', showForRoles: [1, 4, 5] },
  // { path: '/Aprobacion', icon: 'fa fa-file', title: 'Aprobacion', showForRoles: [1] },
  {
    path: '/Rol', icon: 'fa fa-university', title: 'DGAFE', showForRoles: [1,4], hasChildren: true,
    children: [
      { path: '/CargoCuenta', icon: 'fa fa-folder', title: 'Cargos Cuenta', showForRoles: [1, 4] },
      { path: '/FondoRotativo', icon: 'fa fa-folder', title: 'Fondo Rotativos', showForRoles: [1, 4] },
      { path: '/CuentasCobrar', icon: 'fa fa-archive', title: 'Cuentas Por Cobrar', showForRoles: [1, 4] },
      { path: '/CargaFamiliar', icon: 'fa fa-users', title: 'Carga Familiar', showForRoles: [1, 4] },
    ]
  },
  {
    path: '/Rol', icon: 'fa fa-university', title: 'DPTO. VI', showForRoles: [1, 4], hasChildren: true,
    children: [
      { path: '/CargoCuenta', icon: 'fa fa-folder', title: 'Base de Datos', showForRoles: [1, 4] },
      { path: '/CargoCuenta', icon: 'fa fa-folder', title: 'Institutos', showForRoles: [1, 4] },
    ]
  },
  {
    path: '/Rol', icon: 'fa fa-university', title: 'DPTO. IV', showForRoles: [1, 4], hasChildren: true,
    children: [
      { path: '/CargoCuenta', icon: 'fa fa-folder', title: 'Base de Datos', showForRoles: [1, 4] },
      { path: '/CargoCuenta', icon: 'fa fa-folder', title: 'Mat. Bel.', showForRoles: [1, 4] },
    ]
  },
  {
    path: '/Rol', icon: 'fa fa-university', title: 'DPTO. V', showForRoles: [1, 4, 5], hasChildren: true,
    children: [
      { path: '/Certificadodptov', icon: 'fa fa-file', title: 'Generar Certificados Admin', showForRoles: [1, 5] },
      { path: '/PersonaDeuda', icon: 'fa fa-folder', title: 'Base de Datos del DPTO V', showForRoles: [1, 4, 5] },
      { path: '/PersonaActivoDeuda', icon: 'fa fa-folder', title: 'Registro Deuda DPTO V', showForRoles: [1] },

    ]
  },
  {
    path: '/Rol', icon: 'fa fa-university', title: 'DGBPIE', showForRoles: [1, 4], hasChildren: true,
    children: [
      { path: '/CargoCuenta', icon: 'fa fa-folder', title: 'Base de Datos', showForRoles: [1, 4] },
      { path: '/CargoCuenta', icon: 'fa fa-folder', title: 'Deudores', showForRoles: [1, 4] },
    ]
  },
  { path: '/Validador', icon: 'fas fa-info fa-fw', title: 'Validador de Certificados', showForRoles: [1, 4] },
  //{ path: '/Validadorfree', icon: 'fas fa-info fa-fw', title: 'Validador de Certificados', showForRoles: [1, 4] },
  {
    path: '/Rol', icon: 'fas fa-chart-bar', title: 'Dashboards Gral.', showForRoles: [1], hasChildren: true,
    children: [
      { path: '/DashboCerti', title: 'Certificaciones', showForRoles: [1] },
      { path: '/DashboSafi', title: 'SAFI', showForRoles: [1] },
      { path: '/DashboSp', title: 'Servicios Personales', showForRoles: [1] },
      { path: '/DashboContra', title: 'Contrataciones', showForRoles: [1] },
      { path: '/DashboCertiPruebas', title: 'Pruebas', showForRoles: [1] },
    ]
  },

]

export default sidebarMenu;

</script>
