
import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
export default {
    name: "PersonaDeuda",
    data() {
        let ss2 = new MainService();
        return {
            msg: "PersonaDeuda",
            ss2: ss2,
            auth: {},
          //   ajax: {
          //     "url": ss2.indexCertificadoFiltroDeuda(),
          //     headers: ss2.getToken(),

          // },
          // columns: [
          //     { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
          //     { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
          //     { data: 'Persona', name: 'p.Persona', title: 'Nombre y Apellidoss' },
          //     { data: 'CI', name: 'p.CI', title: 'C.I.' },
          //     { data: 'Reparticion', name: 'tc.Reparticion', title: 'Reparticion Adeudada' },
          //     { data: 'Cargo', name: 'c.Cargo', title: 'Cargo' },
          //     { data: 'FechaDeuda', name: 'c.FechaDeuda', title: 'Fecha Deuda' },
              
          //     { data: 'ActivoDeuda', name: 'c.ActivoDeuda', title: 'Activo Deuda' },
          //     {
          //         data: 'EstadoEntrega',
          //         name: 'c.EstadoEntrega',
          //         title: 'Estado Deuda',
          //         render: function(data) {
          //             // Aquí defines la lógica para convertir el estado de deuda de números a texto
          //             switch (data) {
          //                 case 1:
          //                     return 'Deuda';
          //                 case 2:
          //                     return 'Solucionado';
          //                 case 3:
          //                     return 'En Proceso de Solución';
          //                 default:
          //                     return 'Desconocido';
          //             }
          //         }
          //     },
          //     { data: 'FechaSolucion', name: 'c.FechaSolucion', title: 'Fecha Solucion' },
          //     {
          //         data: 'action',
          //         orderable: false,
          //         title: 'Acciones',
          //         searchable: false,
          //     },
          // ],
          

            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE_SAFI,
            certificado: {
                cod: null,
                cod1: null
            },
            consulta: [],
            noDataMessage: ''
        };
    },
    methods: {
        showConsultarCcv() {
            var id = this.certificado.cod;
            console.log(this.certificado.cod);
            console.log(id, "este id q se va");
            this.isLoading = true; // Añadido para mostrar un indicador de carga
            this.ss2.showConsultarCcv(id).then(
              (result) => {
                let response = result.data;
                this.consulta = response.data;
                this.isLoading = false;
                if (this.consulta.length === 0) {
                  this.noDataMessage = "No se encontraron registros.";
                } else {
                  this.noDataMessage = ''; // Limpia el mensaje si hay datos
                }
                window.$('.btn-datatable-PersonaDeuda').on('click', (evt) => {
                  let data = window.$(evt.target)[0].id;
                  console.log(data);
                });
              }
            ).catch(error => {
              console.log(error);
              this.isLoading = false;
              this.noDataMessage = "Error al consultar los datos.";
            });
          },

      
        //   draw() {
        //     window.$('.btn-datatable-personaDeudaa').on('click', (evt) => {
        //         const data = window.$(evt.target)[0].id;
        //         this.showPersonaDeuda(data);
        //     });
        // },


        showPersonaDeuda(id) {
          this.isLoading = true;
          this.ss2.showConsultarDptovv(id).then(
              (result) => {
                  let response = result.data;
                  this.certificado = response.data;
                  this.$refs['view-personaDeuda'].show();
                  this.isLoading = false;
              }

          ).catch(error => {
              console.log(error);
              this.isLoading = false;
          });
      },

          showConsultarDptov() {
            var id = this.certificado.cod1;
            var id2 = this.auth.CI;
            if(id === id2){
              console.log(id, 'este se va ' );
              this.isLoading = true; // Añadido para mostrar un indicador de carga
              this.ss2.showConsultarDptovv(id).then(
                (result) => {
                  let response = result.data;
                  this.consulta = response.data;
                  this.isLoading = false;
                  if (this.consulta.length === 0) {
                    this.noDataMessage = "No se encontraron registros.";
                  } else {
                    this.noDataMessage = ''; // Limpia el mensaje si hay datos
                  }
                  window.$('.btn-datatable-PersonaDeuda').on('click', (evt) => {
                    let data = window.$(evt.target)[0].id;
                    console.log(data);
                  });
                }
              ).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.noDataMessage = "Error al consultar los datos.";
              });
            } else {
              console.log("CI incorrecto");  
              alert("CI incorrecto");
            }
          },
        getDescargoEstadoStyle(descargoEstado) {
            if (descargoEstado === 'Aprobado Usuario supervisor y  Cierre') {
              return { color: 'blue' };
            } else if (descargoEstado === 'Aprobado por Revisor') {
              return { color: 'green' };
            } else if (descargoEstado === 'Sin Descargo') {
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
        },
    },
    components: {
        dataTable,
        Loader
 
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
