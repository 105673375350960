<script>
const pageOptions = {
  pageSidebarMinified: false,
  pageContentFullWidth: false,
  pageContentInverseMode: false,
  pageContentFullHeight: false,
  pageWithLanguageBar: false,
  pageWithFooter: false,
  pageWithoutSidebar: false,
  pageWithRightSidebar: false,
  pageWithTwoSidebar: false,
  pageWithWideSidebar: false,
  pageWithLightSidebar: false,
  pageWithMegaMenu: false,
  pageWithTopMenu: false,
  pageSidebarTransparent: false,
  pageEmpty: false,
  pageMobileSidebarToggled: false,
  pageMobileRightSidebarToggled: false,
  pageMobileTopMenu: false,
  pageMobileMegaMenu: false,
  pageRightSidebarToggled: false,
  pageRightSidebarCollapsed: false,
  pageSidebarSearch: false,
  pageBodyScrollTop: 0
}

export default pageOptions;
</script>