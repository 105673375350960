import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CertificadoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CertificadoPage",
            ss: ss,
            auth: {},

            ajax: {
                "url": ss.indexCertificadoFiltro(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'CodigoCertificado', name: 'c.CodigoCertificado', title: 'Cod. Certificado', width: 50, cellClass: 'center-align', },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'CI', name: 'p.CI', title: 'C.I.' },
                { data: 'FechaElaboracion', name: 'c.FechaElaboracion', title: 'Fecha Elab/Solicitud' },
                { data: 'TipoSolicitud', name: 'c.TipoSolicitud', title: 'TipoSolicitud', render:function(data, type, row){
                    if (row.TipoSolicitud==2){return '<i class="fa fa-envelope text-indigo  "> WEB</i></span>';}
                    else{ return '<i class="fa fa-laptop text-blue "> INTERNO </i></span>';}
                    } 
                },
                {
                    data: 'EstadoCertificado',
                    name: 'c.EstadoCertificado',
                    title: 'Estado',
                    render: function (data, type, row) {
                        switch (row.EstadoCertificado) {
                            case 1:
                                return '<i class="fa fa-times text-danger font-italic font-weight-bold"> Borrador </i>';
                            case 2:
                                return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i>';
                            case 3:
                                return '<i class="fa fa-exclamation-triangle text-warning font-italic font-weight-bold"> Observado </i>';
                            case 4:
                                return '<i class="fa fa-times text-warning font-italic font-weight-bold"> Anulado </i>';
                            case 5:
                                return '<i class="fa fa-paper-plane text-info font-italic font-weight-bold"> Solicitud </i>';
                            case 6:
                                return '<i class="fa fa-dollar-sign text-primary font-italic font-weight-bold"> Pagado </i>';
                            case 7:
                                return '<i class="fa fa-check-double text-secondary font-italic font-weight-bold"> Revisado </i>';
                            default:
                                return '<i class="fa fa-question text-muted font-italic font-weight-bold"> Desconocido </i>';
                        }
                    },
                },
                { data: 'Aprobado', name: 'c.Aprobado', title: 'Aprobado', render:function(data, type, row){
                    if (row.Aprobado==0){return '<i class="fa fa-times text-danger font-italic font-weight-bold "> Borrador</i></span>';}
                    else{ return '<i class="fa fa-check text-success font-italic font-weight-bold"> Aprobado </i></span>';}
                    } 
                },
                { data: 'TipoCertificado', name: 'tc.TipoCertificado', title: 'Tipo Certificado' },
                // { data: 'Concatenado', name: 'c.Concatenado', title: 'Código de Control' },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                },
            ],
            unidadAcademicas: [],
            tipocertificados: [],
            estadocertificados: [],
            certificados: [],
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            certificado: {
                TipoCertificado: null,
                Archivo: null,
                FotoVoucher: null,
            },
            cantidadtipocert: {},
            personas: [],
            searchPersona: "",
            selectedPersona: null,
            personalogueada:{},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            arregloElementos: [],
            cod1: 0,
            cod2: 0,
            cod3: 0,
            cod4: 0,
            cod5: 0,
            cod6: 0,
            reportes: {},
            tipocertificado: {},
            detalledocs: [
                /* 'TipoCertificado', */
                 'Descripcion',
                 'Costo'
                ], // Lista de campos a mostrar
            filePreview: {
                Archivo: null,
                FotoVoucher: null,
            },    
        };
    },

    computed: {
        filteredTipocertificado() {
            let result = [];
            for (let key in this.tipocertificado) {
                if (this.detalledocs.includes(key)) {
                    let value = this.tipocertificado[key];
                    // Si el campo es Descripcion, dividirlo por comas
                    if (key === 'Descripcion') {
                        value = value.split(',').map(item => item.trim());
                    }
                    result.push({ key: key, value: value });
                }
            }
            return result;
        }
    },
    methods: {
        isImage(url) {
            return /\.(jpg|jpeg|png|gif|bmp|svg)$/.test(url);
        },
        isPDF(url) {
            return /\.pdf$/.test(url);
        },
        aplicarFiltro(){
            let PersonaRol = this.auth.id
            this.ajax.data = function(d){
                d.PersonaRol = PersonaRol
            }
            this.$refs['datatable-certificado'].reloadAjax(this.ajax);
        },

        limpiar(){
            document.getElementById("PersonaRol").value = "";
            this.$refs['datatable-certificado'].reloadAjax();
        },

        getTipoCertificado() {
            this.ss.listTipoCertificado().then(
                (result) => {
                    let response = result.data;
                    this.tipocertificados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEstadoCertificado() {
            this.ss.listEstadoCertificado().then(
                (result) => {
                    let response = result.data;
                    this.estadocertificados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        

        loadFile(inputType) {
            this.isLoadingFile = true;
            let input = event.target;
            if (input.files && input.files[0]) {
                let data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            if (inputType === 'Archivo') {
                                this.certificado.Archivo = result.data.data;
                            } else if (inputType === 'FotoVoucher') {
                                this.certificado.FotoVoucher = result.data.data;
                            }
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                                }
                            );
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        );
                        this.isLoadingFile = false;
                    });
            }
        },
        
        showTipoCertificado(id) {
            this.isLoading = true;
            this.ss.showTipoCertificado(id)
                .then(result => {
                    let response = result.data;
                    this.tipocertificado = response.data; // Actualiza los datos del tipo de certificado
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        newCertificado() {
            this.certificado = {};
            this.$refs['frm-certificado'].show();
        },
        
        showCertificado(id) {
            this.isLoading = true;
            this.ss.showCertificado(id).then(
                (result) => {
                    let response = result.data;
                    this.certificado = response.data;
                    this.$refs['view-certificado'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        editCertificado() {
            this.$refs['frm-certificado'].show();
            this.$refs['view-certificado'].hide();
        },

        cancelCertificado() {
            if (this.certificado.id) {
                this.$refs['view-certificado'].show();
            }
            this.$refs['frm-certificado'].hide();
        },

        saveCertificado() {
            this.certificado.TipoSolicitud = 1;
            this.certificado.CodigoCertificado = this.cantidadtipocert + 1;
            this.certificado.Codigo = this.cod6;
            this.certificado.Concatenado = this.cod6;
            this.ss.storeCertificado(this.certificado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-certificado'].hide();
                    this.$refs['datatable-certificado'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        saveCertificadoWeb() {
            this.certificado.Persona = this.personalogueada.id;
            this.certificado.TipoSolicitud = 2;
            this.certificado.EstadoCertificado = 5;
            this.ss.storeCertificado(this.certificado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-certificadosolicitud'].hide();
                    this.$refs['datatable-certificado'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },


        draw() {
            window.$('.btn-datatable-Certificado').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCertificado(data);
            });
        },

        showcantidadtipocert() {
                this.isLoading=true;
                console.log (this.certificado.TipoCertificado);
                this.ss.showcantTipoCertificado(this.certificado.TipoCertificado).then(
                    (result) => {
                        let response = result.data;
                        this.cantidadtipocert = response.data;
                        console.log(this.cantidadtipocert)
                        this.isLoading=false;
                        this.codigoControl();
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
        },

        codigoControl() {
                console.log (this.certificado.CI);
                console.log (this.certificado.NombreCompleto);
                console.log (this.certificado.Nombres);
                console.log (this.certificado.ApellidoPaterno);
                console.log (this.certificado.ApellidoMaterno);
                this.cod1 = this.certificado.Nombres.length
                this.cod2 = this.certificado.ApellidoPaterno.length
                this.cod3 = this.certificado.ApellidoMaterno.length
                this.cod4 = this.certificado.NombreCompleto.length
                this.cod5 = this.certificado.CI.substr(-3);
                console.log (this.cod1);
                console.log (this.cod2);
                console.log (this.cod3);
                console.log (this.cod4);
                console.log (this.cod5);
                this.cod6 = this.cod1+""+this.cod2+""+this.cod3+""+this.cod5+""+(this.cantidadtipocert + 1)+"23"+""+((this.cantidadtipocert + 1)+this.cod1);
                console.log ('este es cod final',this.cod6);
        },

        newSolicitud() {
            this.certificado = {};
            this.$refs['frm-certificadosolicitud'].show();
            this.showpersonalogueada()
        },  

        showpersonalogueada() {
            this.isLoading=true;
            console.log (this.auth.id);
            this.ss.showPersona(this.auth.id).then(
                (result) => {
                    let response = result.data;
                    this.personalogueada = response.data;
                    console.log(this.personalogueada)
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, 
        printCerAprobado() {
            console.log(this.certificado.id);
            this.ss.printCerAprobado(this.certificado.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },

    },
    watch: {
        searchPersona(q) {
            this.ss.select2Persona(q).then((res) => {
                console.log("",res);
                if (res.data.success) {
                    this.personas = res.data.items;
                } else {
                    this.personas = [];
                }
            })
        },
        // selectedPersona(persona){
        //     console.log(persona)
        //     this.certificado.Persona = persona.id;
        //     this.showPersona(persona)
        // },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getTipoCertificado();
            this.getEstadoCertificado();
            this.aplicarFiltro();
        }
    }
};
