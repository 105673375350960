var axios = require('axios');

export default class SpService {

    constructor() {
        
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE_SP;
    }

   //Consultar Carga Familiar
   cargaFamiliar(id) {
    return axios.get('api/Externo/cargaFamiliar?id='+ id);
    }


}
