import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import LineChart from '@/components/vue-chartjs/LineChart'
import BarChart from '@/components/vue-chartjs/BarChart'
import RadarChart from '@/components/vue-chartjs/RadarChart'
import PolarAreaChart from '@/components/vue-chartjs/PolarAreaChart'
import PieChart from '@/components/vue-chartjs/PieChart'
import DoughnutChart from '@/components/vue-chartjs/DoughnutChart'
window.$ = window.jQuery = require("jquery");

export default {
    name: "DashboCerti",
    data () {
        let ss = new MainService();
		return {
            msg: "DashboCerti",
            ss: ss,
            auth: {},
            certis: [],

			lineChart: {
				data: {
					labels: [],
					datasets: [{
							label: 'Cantidad de Certificados',
							borderColor: '#348fe2',
							pointBackgroundColor: '#348fe2',
							pointRadius: 2,
							borderWidth: 2,
							backgroundColor: 'rgba(52, 143, 226, 0.3)',
							data: []
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false,
					hover: {
						mode: 'nearest',
						intersect: true
					},
					tooltips: {
						mode: 'index',
						intersect: false,
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero:true,
								max: 2000
							}
						}]
					}
				}
			},
			barChart: {
				data: {
					labels: [], 
					datasets: [{
						label: 'Cantidad de Certificados por Tipo',
						borderWidth: 2,
						borderColor: '#727cb6',
						backgroundColor: 'rgba(114, 124, 182, 0.3)',
						data: []
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
			radarChart: {
				data: {
					labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
					datasets: [{
						label: 'Dataset 1',
						borderWidth: 2,
						borderColor: '#ff5b57',
						pointBackgroundColor: '#ff5b57',
						pointRadius: 2,
						backgroundColor: 'rgba(255, 91, 87, 0.2)',
						data: [65,59,90,81,56,55,40]
					}, {
						label: 'Dataset 2',
						borderWidth: 2,
						borderColor: '#2d353c',
						pointBackgroundColor: '#2d353c',
						pointRadius: 2,
						backgroundColor: 'rgba(45, 53, 60, 0.2)',
						data: [28,48,40,19,96,27,100]
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
			polarAreaChart: {
				data: {
					labels: ['Dataset 1', 'Dataset 2', 'Dataset 3', 'Dataset 4', 'Dataset 5'],
					datasets: [{
						data: [300, 160, 100, 200, 120],
						backgroundColor: ['rgba(114, 124, 182, 0.7)', 'rgba(52, 143, 226, 0.7)', 'rgba(0, 172, 172, 0.7)', 'rgba(182, 194, 201, 0.7)', 'rgba(45, 53, 60, 0.7)'],
						borderColor: ['#727cb6', '#348fe2', '#00ACAC', '#b6c2c9', '#2d353c'],
						borderWidth: 2,
						label: 'My dataset'
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
			pieChart: {
				data: {
					labels: [],
					datasets: [{
						data: [],
						backgroundColor: ['rgba(114, 124, 182, 0.7)', 'rgba(182, 194, 201, 0.7)', 'rgba(0, 172, 172, 0.7)', 'rgba(245, 156, 26, 0.7)', 'rgba(45, 53, 60, 0.7)', 'rgba(182, 194, 201, 0.7)', 'rgba(45, 53, 60, 0.7)', 'rgba(114, 124, 182, 0.7)', 'rgba(255, 91, 87, 0.7)', 'rgba(0, 172, 172, 0.7)', 'rgba(52, 143, 226, 0.7)', 'rgba(45, 53, 60, 0.7)'],
						borderColor: ['#727cb6', '#b6c2c9', '#00ACAC', '#f59c1a' , '#2d353c' , '#b6c2c9', '#2d353c', '#727cb6', '#ff5b57', '#00ACAC', '#348fe2', '#2d353c' ],
						borderWidth: 2,
						label: 'Estadística por Tipo de Certificado'
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false
				}
			},
			doughnutChart: {
				data: {
					labels: [],
					datasets: [{
						data: [],
						backgroundColor: ['rgba(114, 124, 182, 0.7)', 'rgba(182, 194, 201, 0.7)', 'rgba(0, 172, 172, 0.7)', 'rgba(245, 156, 26, 0.7)', 'rgba(45, 53, 60, 0.7)', 'rgba(182, 194, 201, 0.7)', 'rgba(45, 53, 60, 0.7)', 'rgba(114, 124, 182, 0.7)', 'rgba(255, 91, 87, 0.7)', 'rgba(0, 172, 172, 0.7)', 'rgba(52, 143, 226, 0.7)', 'rgba(45, 53, 60, 0.7)'],
						borderColor: ['#727cb6', '#b6c2c9', '#00ACAC', '#f59c1a' , '#2d353c' , '#b6c2c9', '#2d353c', '#727cb6', '#ff5b57', '#00ACAC', '#348fe2', '#2d353c' ],
						borderWidth: 2,
						label: 'Estadística por Tipo de Certificado'
					}]
				},
				options: {
					responsive: true, 
					maintainAspectRatio: false,
					plugins: {legend: {labels: {font: {size: 5 }}}}
				}
			},
			barChartd: {
				options: {
					chart: {
						height: 150,
						type: 'bar',
					},
					plotOptions: {
						bar: {
							horizontal: true,
							dataLabels: {
								position: 'top',
							},
						}  
					},
					dataLabels: {
						enabled: true,
						offsetX: -6,
						style: {
							fontSize: '10px',
							colors: ['#ffffff']
						}
					},
					
					stroke: {
						show: true,
						width: 1,
						colors: ['#ffffff']
					},
					xaxis: {
						categories: ["TRAMITES PARA ASUMIR CARGOS ADMINISTRATIVOS", "RECOJO DE MEMORANDUM POR RETIRO OBLIGATORIO", "TRAMITE DE RETIRO VOLUNTARIO", "TRAMITE DE JUBILACIÓN", "POSTULACION  A LA ASSSPFAN", "TRAMITE VIAJE AL EXTERIOR", "TRAMITE DE ACTUALIZACION DE DATOS PERSONALES", "RECOJO DE MEMORANDUM PASE AL SERVICIO PASIVO", "TRAMITE DE ASCENSO DE GRADO", "RECOJO DE MEMORANDUM BAJA POR FALLECIMIENTO", "TRAMITE PASE SERVICIO PASIVO", "TRAMITE POSTULACIÓN INSITUTO MILITAR"],
						axisBorder: {
							show: true,
							color: 'rgba(182, 194, 201, 0.5)',
							height: 1,
							width: '50%',
							offsetX: 0,
							offsetY: 0
						},
						axisTicks: {
							show: true,
							borderType: 'solid',
							color: '#b6c2c9',
							height: 20,
							offsetX: 0,
							offsetY: 0
						}
					}
				},
				series: [{
					data: []
					}]
			},
			barChartt: {
				data: [{
					key: 'Cumulative Return',
					values: [
						{ 'label' : '', 'value' : '', 'color' : '#ff5b57' }
					]
				}],
				staggerLabels: false
			},
		}
	},

    methods: { 
		handleResize() {
			this.getCertificados();
			this.getCertificadosmes();
			this.getCertificadostorta();
			this.getCertificadosllanta();
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize);
		},
	
		getCertificados() {
            this.ss.estadisticaCertificado().then(
                (result) => {
                let response = result.data;
				let labels = [];
				let data = [];

					for (let i = 0; i < response.length; i++) {
						labels.push(response[i].TipoCertificado);
						data.push(response[i].count);
					}

					//this.certis = response;
                    this.barChart.data.labels = labels;
					this.barChart.data.datasets[0].data = data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

		getCertificadosmes() {
            this.ss.estadisticaCertificadomes().then(
                (result) => {
                    let response = result.data;
                    this.lineChart.data.labels = response.map(item => item.mes);
					this.lineChart.data.datasets[0].data = response.map(item => item.cantidad);	
                }
            ).catch(error => {
                console.log(error);
            });
        },

		getCertificadostorta() {
            this.ss.estadisticaCertificado().then(
                (result) => {
                    let response = result.data;
                    this.pieChart.data.labels = response.map(item => item.TipoCertificado);
					this.pieChart.data.datasets[0].data = response.map(item => item.count);	
                }
            ).catch(error => {
                console.log(error);
            });
        },
		
		getCertificadosllanta() {
            this.ss.estadisticaCertificado().then(
                (result) => {
                    let response = result.data;
                    this.doughnutChart.data.labels = response.map(item => item.TipoCertificado);
					this.doughnutChart.data.datasets[0].data = response.map(item => item.count);	
                }
            ).catch(error => {
                console.log(error);
            });
        },
		getCertificadosdos() {
            this.ss.estadisticaCertificado().then(
                (result) => {
                let response = result.data;
				//this.certis = response.map(item => `"${item.TipoCertificado}"`).join(", ").slice(1, -1);
                    this.barChartd.options.xaxis.categories= response.map(item => `"${item.TipoCertificado}"`).join(", ").slice(1, -1);
					this.barChartd.series[0].data = response.map(item => item.count);	
                }
            ).catch(error => {
                console.log(error);
            });
        },
		getCertificadostres() {
			this.ss.estadisticaCertificadodos().then(
				(result) => {
				let response = result.data;
				//this.barChartt.data[0].values = response.map((item, index) => ({
				this.barChartt.data[0].values = response.map((item) => ({
					color: item.color,
					//label: String.fromCharCode(65 + index),
					label: item.TipoCertificado,
					series: 0,
					value: item.count
				}));
				}
			).catch(error => {
				console.log(error);
			});
			}
       
    },
    components: {
        dataTable,
        Loader,
        LineChart,
        BarChart,
        RadarChart,
        PolarAreaChart,
        PieChart,
        DoughnutChart
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } else {
			this.getCertificados();
			this.getCertificadosdos();
			this.getCertificadostres();
			this.getCertificadosmes();
			this.getCertificadostorta();
			this.getCertificadosllanta();
			window.addEventListener('resize', this.handleResize);
		} 
    }
};
