
import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ValidadorPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ValidadorPage",
            ss: ss,
            auth: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            hours: 0,
            minutes: 0,
            seconds: 0,
            codigo: 0,            
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            certificado: {
                cod: null,
                cod2: null,
                ci: null
            },
            consulta: {},
            consultaci: {}
        };
    },
    methods: {
        setTime() {
            const date = new Date();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();
            hours = hours <= 9 ? `${hours}`.padStart(2, 0) : hours;
            minutes = minutes <= 9 ? `${minutes}`.padStart(2, 0) : minutes;
            seconds = seconds <= 9 ? `${seconds}`.padStart(2, 0) : seconds;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;
          },
          printConsultar() {
            this.ss.ConsultarCertificado(this.certificado)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        },
        showConsultar() {
            var id = this.certificado.cod2
            console.log (this.certificado.cod2);
            console.log (id, "este id q se va");
            this.ss.showconsulta(id).then(
                (result) => {
                    let response = result.data;
                    this.consulta = response.data;
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        showConsultarci() {
            var id = this.certificado.ci
            console.log (this.certificado.ci);
            console.log (id, "este id q se va");
            this.ss.showconsultaci(id).then(
                (result) => {
                    let response = result.data;
                    this.consultaci = response.data;
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
    },
    components: {
        dataTable,
        Loader
 
    },
    mounted() {
        setInterval(() => this.setTime(), 1000)
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
            this.$router.push('/Login');
        } 
    }
};
